
import { defineComponent } from 'vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  props: {
    widgetArrow: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
  },
  setup() {
    const { chatHidden, widgetAppearanceSettings } = useWidget()
    return {
      chatHidden,
      widgetAppearanceSettings,
    }
  },
})
