import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03786559"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chatting_block = _resolveComponent("chatting-block")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_chatting_button = _resolveComponent("chatting-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["chatting-block__wrap", {
        'chatting-block__wrap--visible': !_ctx.chatHidden,
        'chatting-block__wrap--static': _ctx.static,
      }])
    }, [
      _createVNode(_component_chatting_block)
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["chatting-widget__tooltip", {
        'chatting-widget__tooltip--show': _ctx.widgetAppearanceSettings.showTooltip && _ctx.chatHidden,
        'chatting-widget__tooltip--static': _ctx.static,
      }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.widgetAppearanceSettings.messageText) + " ", 1),
      _createVNode(_component_tm_icon, {
        name: "close",
        class: "chatting-widget__tooltip-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.widgetAppearanceSettings.showTooltip = false))
      })
    ], 2), [
      [_vShow, _ctx.chatHidden]
    ]),
    _createVNode(_component_chatting_button, {
      "widget-arrow": _ctx.widgetArrow,
      static: _ctx.static,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.bindChat()))
    }, null, 8, ["widget-arrow", "static"])
  ]))
}