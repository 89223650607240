import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "subhead-semi-bold-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chatting-button", {
      'chatting-button--widget-arrow': _ctx.widgetArrow && _ctx.chatHidden,
      'chatting-button--static': _ctx.static,
      'chatting-button--with-text': _ctx.widgetAppearanceSettings.buttonText?.length,
      [`chatting-button--${_ctx.widgetAppearanceSettings.primaryColor}`]: true,
      'chatting-button--gradient': _ctx.widgetAppearanceSettings.isGradient,
    }])
  }, [
    _createVNode(_component_tm_icon, {
      name: _ctx.chatHidden ? 'tmi-textmagic' : 'expand_more',
      "custom-size": "26px"
    }, null, 8, ["name"]),
    (_ctx.widgetAppearanceSettings.buttonText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.widgetAppearanceSettings.buttonText), 1))
      : _createCommentVNode("", true)
  ], 2))
}