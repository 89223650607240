
import { defineComponent } from 'vue'
import ChattingButton from '@/components/pages/chattingWidget/ChattingButton.vue'
import ChattingBlock from '@/components/pages/chattingWidget/ChattingBlock.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    ChattingBlock,
    ChattingButton,
  },
  props: {
    widgetArrow: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    autostartTooltip: {
      type: Boolean,
    },
  },
  setup(props) {
    const { bindChat, chatHidden, widgetAppearanceSettings } = useWidget()

    if (props.autostartTooltip) {
      setTimeout(() => {
        widgetAppearanceSettings.showTooltip = true
      }, 2500)
    }

    return {
      chatHidden,
      bindChat,
      widgetAppearanceSettings,
    }
  },
})
