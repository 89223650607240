import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a8522f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatting-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chatting_content = _resolveComponent("chatting-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chatting_content)
  ]))
}