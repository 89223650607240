
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import WidgetEmbedCode from '@/components/pages/chattingWidget/WidgetEmbedCode.vue'
import ChattingWidget from '@/components/pages/chattingWidget/ChattingWidget.vue'

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
    WidgetEmbedCode,
    ChattingWidget,
  },
})
