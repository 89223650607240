
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { copyToClipboard } from '@/services/utils'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import CodeTag from '@/components/shared/templates/CodeTag.vue'
import CodeSnippet from '@/components/shared/templates/CodeSnippet.vue'
import TmModalContainer from '@/components/modals/TmModalContainer.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    CodeTag,
    CodeSnippet,
    TmModalContainer,
  },
  setup() {
    const { openModal } = useModals()
    const embedCode = ref('<script type="text/javascript"> const script = document.createElement(“script”); script.src = “https://widgets.touchpointtesting.com/widget-script.js”; script.type = “text/javascript”; script.async = true; window[“__tp-widget-id__“] = “0184d2bc-7580-7391-b33b-36e4f088fa1c”; const s = document.getElementsByTagName(“script”)[0]; s.parentNode.insertBefore(script, s); <script>')
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
    }

    const openSendInstructionsModal = () => {
      openModal('singleInput', {
        modalTitle: 'Send instructions',
        description: 'Enter your developer’s email address and we\'ll send them everything they need to install these records.',
        label: 'Send to',
        placeholder: 'Enter email address',
        validators: 'required',
        btnText: 'Send instructions',
      })
    }

    return {
      copied,
      copyFunc,
      embedCode,
      openSendInstructionsModal,
    }
  },
})
